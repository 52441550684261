// import React from 'react';
import auth0 from 'auth0-js';
import { USER_ROLES } from '../AuthContext';

export default class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: 'abusix-intern.eu.auth0.com',
      clientID: 'N2gYSPX1oAC4T6sRp9eN8SYq2v5G38Qe',
      redirectUri: `${window.location.origin}/`,
      responseType: 'token id_token',
      scope: 'openid email',
      audience: 'https://www.abusix.ai/api',
      state: encodeURIComponent(window.location.href),
    });
  }

  checkExpired() {
    const now = new Date(Date.now());
    const expires = new Date(this._authResult.expiresAt);
    let renewBeforeExpires = new Date(this._authResult.expiresAt);
    renewBeforeExpires.setMinutes(renewBeforeExpires.getMinutes() - 5);
    if (expires < now) {
      this.logout();
      return true;
    }
    if (renewBeforeExpires < now) {
      this.renewSession();
      return true;
    }
    setTimeout(() => this.checkExpired(), 1000);
    return false;
  }

  authenticate() {
    if (window.location.href.includes('access_token=')) {
      this.handleAuthentication();
      return false;
    }

    if (!this.isAuthenticated()) {
      this.login();
      return false;
    }
    if (this.checkExpired()) {
      this.logout();
      return false;
    }
    if (this.sessionTimeout) {
      clearTimeout(this.sessionTimeout);
    }
    this.sessionTimeout = setTimeout(
      () => this.logout(),
      new Date(this._authResult.expiresAt) - Date.now()
    );
    this.checkExpired();
    return true;
  }

  login() {
    this.auth0.authorize({
      // force google account selection
      prompt: 'select_account',
    });
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        const redirectURL = decodeURIComponent(authResult.state);
        if (
          !(
            redirectURL == null ||
            redirectURL.includes('logout') ||
            redirectURL.includes('.auth0.com')
          )
        ) {
          window.location.href = redirectURL;
        } else {
          window.location.href = '/';
        }
      } else if (err) {
        console.log(err);
        alert(`Login failed: ${err.error}.`);
        this.login();
      }
    });
  }

  getAccessToken() {
    return this.getSession().accessToken;
  }

  getIdToken() {
    return this.getSession().idToken;
  }

  setSession(authResult) {
    authResult.expiresAt = authResult.expiresIn * 1000 + Date.now();
    localStorage.setItem('auth', JSON.stringify(authResult));
    this._authResult = authResult;
  }

  getSession() {
    if (this._authResult) return this._authResult;
    this._authResult = JSON.parse(localStorage.getItem('auth'));
    return this._authResult;
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.checkExpired();
      } else if (err) {
        this.logout();
        console.log(err);
        // alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
      }
    });
  }

  logout() {
    localStorage.removeItem('auth');
    this.auth0.logout({ returnTo: `${window.location.origin}/` });
  }

  isAuthenticated() {
    const authResult = this.getSession();
    if (!authResult) return false;
    return authResult.expiresAt >= Date.now();
  }

  getEmail() {
    return this.getSession().idTokenPayload.email;
  }

  getRole() {
    const sales = ['tommy.cypert@abusix.com', 'lellis@abusix.com', 'pedro@abusix.com'];
    const email = this.getSession().idTokenPayload.email;
    return sales.includes(email) ? USER_ROLES.SALES : USER_ROLES.DEV;
  }
}
