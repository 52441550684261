import React from 'react';

export const USER_ROLES = {
  SALES: 'SALES',
  DEV: 'DEV',
};

export const AuthContext = React.createContext();

export const useAuth = () => React.useContext(AuthContext);
