/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { Pane, Text, ThemeProvider, defaultTheme } from 'evergreen-ui';
import Auth from './lib/Auth';
import { ApolloProvider, InMemoryCache, HttpLink, ApolloClient } from '@apollo/client';
import { AuthContext } from './AuthContext';

export const wrapRootElement = ({ element }) => {
  const auth = new Auth();

  if (!auth.authenticate()) {
    return (
      <Pane height="100vh" width="100vw" display="flex" alignItems="center" justifyContent="center">
        <Text fontSize="50pt">
          <span role="img" aria-label="bye">
            ⏳
          </span>
        </Text>
      </Pane>
    );
  }
  window.logout = () => auth.logout();

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  };

  const client = new ApolloClient({
    link: new HttpLink({
      uri: `${process.env.APP_API_URL}/graphql`,
      headers: {
        Authorization: `Bearer ${auth.getAccessToken()}`,
      },
    }),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });

  const AuthContextProvider = ({ children }) => {
    return (
      <AuthContext.Provider
        value={{
          userRole: auth.getRole(),
          accessToken: auth.getAccessToken(),
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
  return (
    <ApolloProvider client={client}>
      <ThemeProvider value={defaultTheme}>
        <AuthContextProvider>{element}</AuthContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};
